/*Fuentes*/
/*Fuentes*/
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),url("./fonts/Montserrat-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat-ExtraBold"), url("./fonts/Montserrat-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"), url("./fonts/Montserrat-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"), url("./fonts/Montserrat-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat-Black"), url("./fonts/Montserrat-Black.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"), url("./fonts/Montserrat-Light.otf") format("opentype");
}
body{
  background-color: #CA3FBE;
  font-family: "Montserrat-Light";
}
/*Menu*/
.navbar {
  background: rgba(216, 0, 181, .3);
  backdrop-filter: blur(5px);
}

.navbar-toggler-icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(217deg) brightness(106%) contrast(101%);
}

.nav-link {
  font-family:"Montserrat-Light";
  font-size: 18px;
  cursor: pointer;
  color: white;
}
.nav-item, .navbar-brand{
  margin: 0 30px;
}
.nav-link:focus,
.nav-link:hover {
  color: white;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: white;
}
/*Secciones*/
.section {
  display: grid;
  place-items: center;
  font-weight: bold;
}

/*Home*/
#home {
  background-image: url("../public/images/fondos/fono03.jpg");
  background-size: cover;
}
.size-img{
  margin-top: 100px;
  margin-bottom: 20px;
  width: 400px;
}
.size-btn{
  margin-bottom: 10px;
  width: 300px;
}
.pasos{
  margin: 0px 50px;
}
.steps{
  font-family: "Montserrat-Light";
  letter-spacing: 5px;
  font-size: 30px;
  color: white;
}
.textHome{
  font-family:"Montserrat-Bold" ;
  font-size: 30px;
  color: white;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.iconos{
  width: 120px;
	height: 120px;
	object-fit: scale-down;
}
/*Thanks*/
.sectionThanks {
  display: grid;
  place-items: center;
  height: 80vh;
  font-weight: bold;
}
.imgThanks{
  width: 300px;
}
/*Registro*/
#registro {
  background-image: url("../public/images/fondos/fono01.jpg");
  background-size: cover;
}
.contenedor {
  width: 50%;
}
.titulos{
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
  color: white;
}
.titulos2{
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
  color: white;
  font-size: 20px;
}
.textoRegistro{
  font-family: "Montserrat-Light";
  color: white;
  font-size: 28px;
} 
.formulario {
  background-color: transparent !important;
  border: 1px solid #fff;
  border-radius: 12px;
  margin-bottom: 16px;
  text-align: center;
  text-align-last: center;
  font-size: 18px;
  color: #fff;
  height: 80%;
}
.formulario::placeholder {
  color: white;
}

.form-control:focus {
  background-color: transparent;
  color: white;
}
.border-success{
  border: 2px solid;
}
.border-danger{
  border: 2px solid;
}

.upload-container>input {
  display: none;
}

.upload-button {
  outline: none;
  text-transform: uppercase;
  color: #fff;
  background: #ef7e80;
  font-size: 22px;
  border-radius: 50px;
  padding: 5px 40px;
  border: 0;
}
.upload-button:active, .upload-button:hover{
  color: white;
  background: #e99e9f;
}
.upload-button-success {
  outline: none;
  text-transform: uppercase;
  color: #fff;
  background: green;
  font-size: 22px;
  border-radius: 50px;
  padding: 5px 40px;
  border: 0;
}
#historia{
  height: 150px;
}
.form-label {
  font-size: 20px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: transparent;
  color: white;
  outline: none;
  transition: border .24s ease-in-out;
}

.imagenPreview {
  display: 'block';
  width: 'auto';
  height: '100%';
}
/* Registro CuentaHabiente*/
#obtnerID{
  background-image: url("../public/images/fondos/fono07.jpg");
  background-size: cover;
}
.contenedorObtenerCuenta{
  max-width: 500px;
  padding-top: 50px;
  padding-bottom: 50px;
}
/*Anteriores*/
#anteriores{
  background-image: url("../public/images/fondos/fono04.jpg");
  background-size: cover;
}
.anterioresContainer{
  max-width: 900px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color: white;
  background: #ef7e80;
  border: none;
}
.nav-tabs {
  border-bottom: 3px solid #ef7e80;
}
.card{
  max-height: 450px;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Premio */
#premio{
  background-image: url("../public/images/fondos/fono07.jpg");
  background-size: cover;
}
.imgPremio{
  margin-top: 70px;
  margin-bottom: 70px;
  width: 900px;
}
/*Participar*/
#participar {
  background: #ef7e80;
}
/*Termiinos*/
#terminos{
  background-image: url("../public/images/fondos/fono02.jpg");
  background-size: cover;
}
.contenedorTerminos{
  width: 800px;
  height: 600px;
  overflow: auto;
}
.contenedorTerminos::-webkit-scrollbar{
  width: 4px;
}
.list-group-numbered{
  font-size: 20px;
  color: white;
}
.textoTerminos{
  font-size: 20px;
  color: white;
}
/*footer*/
.fondoFotter {
  background-color: #CA3FBE;;
  color: white;
}

.letrasFooter {
  font-family: "Montserrat-Light";
  font-size: 13px;
  text-decoration: none;
  color: white;
  letter-spacing: 1px;
}

.titulosMons {
  font-family: "Montserrat-Bold";
  font-size: 18px;
  letter-spacing: 1px;
}

.titulosCasate {
  font-size: 23px;
  font-family: "Montserrat-ExtraBold";
}
.iconFooterMarcas{
  width: 250px;
}
.iconFooter{
  width: 200px;
}
.iconFooterW {
  width: 100px;
  margin-top: 15px;
}
.iconFooterNat{
  width: 200px;
}
/*Media queries*/
@media (max-width: 576px) {
  #home {
    background-image: url("../public/images/fondos/fono03.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
  .card{
    max-height: 470px;
  }
  .section {
    height: auto;
  }

  .contenedor {
    width: 100%;
  }
  .size-img{
    width: 300px;
  }
  .contenedorTerminos{
    width: 100%;
  }
  .imgPremio{
    width: 100%;
  }
  .iconFooterMarcas{
    width: 130px;
  }
  .iconFooter{
    width: 200px;
  }
  .iconFooterW {
    width: 90px;
    margin-top: 15px;
  }
  
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .iconFooterMarcas{
    width: 160px;
  }
}
